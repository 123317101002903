@import "@styles/variables.scss";

.floating-input-wrapper {
  height: 85px;
}

h3 {
  display: flex;
  color: $amway-black;
  vertical-align: bottom;
  font-family: 'source-sans-pro-bold', 'Arial';
  margin: 0;
  align-items: center;
}

.container {
  padding: 0px;
  overflow-x: auto;
}

.header {
  padding-top: 16px;
}

.label {
  margin-top: 16px;
  width: 100%;
}

.input {
  background-image: none !important;
}

.icon {
  font-size: 15px !important;
  line-height: 11px !important;
  color: $amway-light-green;
  vertical-align: text-top;
  margin-left: 4px;
}

.td {
  min-width: 80px;
}

@media (max-width: 575px) {
  h3 {
    padding: 0px !important;
  }
}
