@import "@styles/variables.scss";

.content {
  padding: 0px 0px 100px 0px !important;
}

.wrapper {
  padding: 16px 0px 32px 0px;
}

h4 {
  font-size: 12px;
  display: flex;
  color: $amway-black;
  vertical-align: bottom;
  font-family: 'source-sans-pro-bold', 'Arial';
  margin: 0px;
  align-items: center;
}

.left {
  margin: 0px 0px 0px -26px;
}

.info-bar {
  padding: 16px 16px 0px;
}

.table {

  td {
    padding: 4px !important;
  }
}

@media (max-width: 575px) {

  .wrapper {
    padding: 16px 24px 32px 24px;
  }

  .left {
    margin: 0px 0px 0px -24px;
  }

}
