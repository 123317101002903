@import "@styles/variables.scss";

.icon {
  font-size: 15px;
  line-height: 11px !important;
  color: $amway-light-green;
  vertical-align: text-top;
  margin-left: 16px !important;
}

.front {
  box-sizing: border-box;
  margin-left: 8px;
  padding: 2px 8px 2px 8px;
  font-size: 12px;
  line-height: 1.5;
  display: inline-block;
  color: $amway-light-green;
  background-color: $amway-light-accent;
  border: 1px solid transparent;
  border-radius: 4px;
}
