@import "@styles/variables.scss";

.label {
  display: flex;
  color: $amway-black !important;
  padding: 0px !important;
}

.checkbox {
  accent-color: $amway-dark-green;
  vertical-align: middle;
}

.number {
  &.border {
    border-bottom: 1px solid $light-black-spacer;
  }
}

.select {
  width: 100%;
  height: 50px;
  font-size: 12px;
  color: $amway-light-green;
  border-bottom: 1px solid $light-black-spacer;
}
