@import "@styles/variables.scss";

.section {
  text-transform: uppercase;
  font-family: 'source-sans-pro-bold', 'Arial';
  font-size: 13px;
  position: relative;
  padding-bottom: 16px;
}

.list-item {
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.025);
  }

  &.active {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &.space {
    &:nth-child(9) {
      margin-bottom: 64px;
    }
  }

  &.border {
    &:nth-child(1) {
      border-top: 1px solid #E4E4E4;
    }

    &:nth-child(10) {
      border-top: 1px solid #E4E4E4;
    }

    &:last-child {
      border-bottom: 1px solid #E4E4E4;
    }
  }
}

.list-item-icon {
  font-size: 14px;
  display: 'block';
  color: $amway-black;
}

.list-item-header {
  font-size: 16px;
  line-height: 21px;
  display: flex;
  flex-direction: row;
  flex: 1;

  span {
    font-size: 18px !important;
    display: 'block';
    color: $amway-black;
  }

  .list-item-title {
    flex: 1;
  }

  .list-item-extra {
    color: $mid-gray-on-white;
  }

  &>span:first-child {
    display: block;
    font-size: 21px;
    margin-right: 12px;
  }
}
