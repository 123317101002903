@import "@styles/variables.scss";

.card-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
  color: $amway-light-green;

  .card-title {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
  }

  .card-reward {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: 12px;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: end;

    .reward-value {
      font-size: 14px;
      font-weight: bold;
    }

    .reward-period {
      font-size: 9px;
      font-weight: bold;
      margin-top: -6px;
    }
  }

  &.disabled {
    color: $amway-black;

    .card-title {
      font-weight: 500;
    }

    .divider {
      border-bottom: 1px dashed $mid-gray-on-white;
    }

    .card-info {
      display: flex;
      margin-left: 16px;
      margin-right: 8px;
      font-size: 16px;
      color: $amway-light-green;
      cursor: pointer;
    }
  }
}

@media (max-width: 575px) {
  .card-title {
    font-size: 13px !important;
  }

  .reward-value {
    font-size: 13px !important;
  }
}
