@import "@styles/variables.scss";

.content {
  padding-bottom: 80px !important;
}

.section-group {
  margin-top: 16px;
  background-color: transparent;
  border: none;
  position: relative;

  &>* {
    border-radius: 4px;
    overflow: hidden;
    background-color: #FFFFFF;
    margin-bottom: 16px;
  }
}

.section-header {
  display: flex;
  flex-direction: row;
}

.icon {
  font-size: 14px !important;
  color: $amway-black !important;

  &.active {
    color: $amway-white !important;
  }
}
