@import "@styles/variables.scss";

.container-button {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
  position: relative;
  border-bottom: 1px solid $light-black-spacer;
}

.container-button>* {
  flex: auto;
  width: 32%;
  font-weight: 500;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $amway-black;
  font-size: 12px;
  font-weight: bold;
}

.button {
  display: flex;
  height: 100%;
  color: $amway-black;
  padding: 0;
  align-items: center;

  &.active {
    color: $amway-light-green;
    font-weight: bold;
  }
}

.button:nth-of-type(odd) {
  justify-content: flex-start;
}

.button:nth-of-type(even) {
  justify-content: flex-end;
}
