@import "@styles/variables.scss";

.icon {
  font-size: 15px !important;
  line-height: 11px !important;
  color: $amway-light-green;

  &.left {
    margin-right: 16px !important;
  }

  &.right {
    margin-left: 16px !important;
  }

  &.white {
    color: $amway-white !important;
  }
}

@media (max-width: 575px) {

  .icon {

    &.left {
      margin-right: 8px !important;
    }

    &.right {
      margin-left: 8px !important;
    }

    &.white {
      color: $amway-white !important;
    }
  }

}
