@import "@styles/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 600px;
  margin-bottom: 60px;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 280px;

    .image {
      width: 140px;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 160px;

    h3 {
      display: block;
      text-align: center;
      line-height: 28px;
      font-size: 18px;
      color: $amway-black;
      padding: 0px 160px;
      font-family: 'source-sans-pro-medium', 'Arial';
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 140px;

    button {
      height: 50px !important;
      width: 180px !important;
      background-color: $amway-black !important;
      border: 0;
      margin: 20px 0px 30px 0px;
      font-size: 20px !important;
      font-family: 'source-sans-pro-bold', 'Arial';

      &:hover {
        background-color: $amway-black;
      }
    }

    a {
      display: flex;
      color: $amway-black;
      font-size: 18px !important;
      font-family: 'source-sans-pro-medium', 'Arial';
      text-decoration: underline;
      text-underline-offset: 4px;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
}

@media (max-width: 575px) {
  h3 {
    padding: 0px 20px;
  }
}
