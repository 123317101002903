@import "@styles/variables.scss";

.info-bar {
  background: $amway-white;
  color: $amway-black;
  font-weight: 500;
  font-family: 'source-sans-pro-bold', 'Arial';
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 0px;
  gap: 8px;
  flex-wrap: wrap;
  border-bottom: 1px solid $light-black-spacer;

  .info-bar-body {
    display: flex;
    flex: 1;
    text-align: left;
    align-items: center;
  }

  .info-bar-extra {
    white-space: nowrap;
    text-align: right;
    color: $amway-black;
    text-transform: capitalize;
  }
}
