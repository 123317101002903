@import "@styles/variables.scss";


.container-info {
  text-align: center;
  padding: 16px;
  color: $amway-black;
  font-size: 11px;
  margin-bottom: 24px;

  .title {
    font-weight: bold;
    margin-bottom: 32px;
  }

  .container-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
    position: relative;
  }

  .container-box>* {
    flex: auto;
    width: 32%;
  }

  .box {
    height: 50px;
    background: $amway-light-green;
    color: white;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
  }

  .box:nth-of-type(odd) {
    margin-right: 4px;
  }

  .box:nth-of-type(even) {
    margin-left: 4px;
  }

  .circle {
    width: 27px;
    height: 27px;
    line-height: 27px;
    border-radius: 50%;
    font-size: 11px;
    font-weight: bold;
    color: $amway-black;
    text-align: center;
    background: $amway-white;
    position: absolute;
    left: 0;
    right: 0;
    margin: 12px auto;
  }
}
