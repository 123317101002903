.flex-row {
  display: flex;
  flex-direction: row;
  gap: 16px;

  &>.flex-col {
    flex: 1;
  }

  &.flex-row-collapse {
    @media (max-width: 575px) {
      flex-direction: column;
    }
  }
}
