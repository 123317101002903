@import "@styles/variables.scss";

.container {
  overflow-x: auto;
}

.table {
  color: $amway-black;
  border-width: 1px;
  border-color: $light-black-spacer;
  table-layout: fixed;
  min-width: 100%;

  &.last {
    border-bottom-style: none;
  }

  tr {
    height: 50px;
  }

  tr.header {
    .cell {
      font-weight: 500;
      vertical-align: bottom;
    }


    &:first-child td {
      border-top-style: none;
    }
  }

  tr.disabled {
    .cell {
      color: $disabled-color;
    }
  }

  td.cell {
    height: 50px;
    font-size: 13px;
    border-width: 1px;
    border-color: $light-black-spacer;
    padding: 8px 0px 8px 0px !important;
  }

  // Custom Padding

  td.none {
    padding: 0px !important;

    &:first-child {
      padding-left: 0px !important;
    }

    &:last-child {
      padding-right: 0px !important;
    }
  }

  td.small {
    padding: 4px !important;

    &:first-child {
      padding-left: 4px !important;
    }

    &:last-child {
      padding-right: 4px !important;
    }
  }

  td.normal {
    padding: 8px !important;

    &:first-child {
      padding-left: 8px !important;
    }

    &:last-child {
      padding-right: 8px !important;
    }
  }

  td.large {
    padding: 16px !important;

    &:first-child {
      padding-left: 16px !important;
    }

    &:last-child {
      padding-right: 16px !important;
    }
  }

  td.left {
    padding: 8px 0px 8px 16px !important;
  }

  // Custom Font

  .normal {
    font-weight: normal !important;
    font-family: 'source-sans-pro-regular', 'Arial';
    color: $amway-black;
  }

  .bold {
    font-weight: 500 !important;
    font-family: 'source-sans-pro-bold', 'Arial';
    color: $amway-black;
  }

  .gray {
    font-weight: normal !important;
    color: $mid-gray-on-white;
  }

  // Custom Layout

  .horizontal {
    display: flex;
    flex-direction: row;

    :first-child {
      margin-right: 4px;
    }
  }

  .vertical {
    display: flex;
    flex-direction: column;
  }

}
