@import "@styles/variables.scss";

.panel-wrapper {
  position: fixed;
  left: 16px;
  bottom: 16px;
  right: 16px;
}

.panel-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 768px;
  margin: 0px auto;
  border-radius: 30px;
  background-color: $amway-light-green;
  padding: 4px 32px 4px 32px;
  height: 60px;

  &.disabledButton {
    padding-right: 16px;
  }
}

.panel-table-wrapper {
  padding: 10px;
}

.panel-table {
  width: 100%;

  th {
    text-align: right;
  }

  th:first-child {
    display: flex;
  }

  td {
    color: $amway-white;
    border-color: transparent;
    text-align: right;
  }

  td:first-child {
    text-align: left;
  }

  tr:first-child td {
    border-top-color: transparent;
  }

  tr:last-child td {
    border-bottom-color: transparent;
  }
}

.panel-bar {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 16px;
  align-items: center;
  justify-content: stretch;
}

.panel-title {
  width: 54px;
  height: 100%;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  flex-grow: 1;
  font-family: 'source-sans-pro-bold', 'Arial';
}

.panel-field {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  color: $amway-white;
  align-items: flex-start;

  label {
    font-size: 12px;
    line-height: 16px;
    text-align: start;
    text-transform: capitalize;
    font-family: 'source-sans-pro-regular', 'Arial';
  }

  .panel-field-value {
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    text-align: left;
    flex: 1;
    font-family: 'source-sans-pro-bold', 'Arial';
  }
}

.panel-icon-button {
  display: block;
  width: 36px;
  height: 36px;
  text-align: end;
  border-color: transparent;

  &>* {
    font-size: 20px;
    color: $amway-light-green;
  }
}

.section {
  display: flex;

  &.last {
    padding-top: 16px;
  }
}

.icon {
  font-size: 14px !important;
  color: $amway-white !important;
}

h4 {
  color: $amway-white;
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 575px) {

  .panel-wrapper {
    bottom: 0px;
    left: 0;
    right: 0;
  }

  .panel-container {
    width: 100%;
    border-radius: 0px;
    padding: 4px 12px 4px 20px;
  }

  .panel-bar {
    gap: 8px;
  }

  .panel-title {
    text-align: left;
    flex-grow: 1;
  }

  .panel-field {
    flex-grow: 3;
  }

  .panel-bar {
    justify-content: space-between;
  }
}
