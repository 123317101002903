.debug-panel {
  position: fixed;
  top: 0;
  right: 0;
  color: #000000;
  padding: 4px;
  z-index: 11;
  display: flex;
  flex-direction: row;
  gap: 6px;
  transition: background-color 250ms;
  font-size: 11px;
  line-height: 13px;

  &.expanded {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .debug-panel-icon {
    display: block;
    cursor: pointer;
  }
}
