@import "@styles/variables.scss";

.card-input {
  padding: 16px;
}

.form-field {
  width: 100%;
  background-color: red;
}

.card-children {
  padding: 0px 16px 16px 16px;
}

.card-sticky {
  border: 0px;
}

.card-wrapper {
  position: relative;
  padding-bottom: 16px;
}

.card-expand {
  position: absolute;
  right: 0px;
  bottom: 0px;
  background-color: #FFFFFF;
  text-align: center;
  line-height: 32px;
  border-radius: 0px 0px 16px 16px;
  border-bottom: 1px solid $light-gray-1-spacer;
  border-right: 1px solid $light-gray-1-spacer;
}

.card-expand-inner {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: $amway-light-green;
  cursor: pointer;
  display: flex;
}

.icon {
  flex: 1;
  align-self: center;
  font-size: 12px;
  margin: 1px;
  color: $amway-white;
}
