@import "@styles/variables.scss";

.layout {
  width: 100%;

  .header {
    z-index: 1;
    line-height: 16px;
    background-color: $amway-white;
    box-shadow: 0 4px 10px 0 rgba(44, 44, 44, .1);

    &.sticky {
      top: 0;
      position: sticky;
    }

    .header-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      max-width: 768px;
      margin: 0px auto;
    }

    h1 {
      flex: 1;
      text-align: center;
      line-height: 18px;
      font-size: 16px;
      font-weight: 500;
      color: $amway-black;
      margin: 0;
      font-family: 'source-sans-pro-medium', 'Arial';
    }

    .header-edge {
      width: 40px;
    }

    .header-button {
      display: block;
      border-color: transparent;
      color: $amway-black;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 100%;
    max-width: 768px;
    margin: 0px auto;
  }
}
