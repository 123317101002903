@import "./variables.scss";
@import "./antd-theme.scss";
@import "./fonts.scss";

#root {
  min-height: 100%;
  position: relative;
  display: flex;
}

.i18n-debug-active {
  outline: 2px solid #2196f3;
  cursor: cell;
}

.section,
.ant-table.section,
.ant-form.section {

  &:last-child {
    padding-bottom: 16px;
  }

  &.section-full-width {
    padding-left: 0px;
    padding-right: 0px;
  }

  &.section-rewards {
    padding: 32px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &.section-footer {
    padding: 32px 0px 0px 0px;
    background: $amway-white;
    border-top: 1px solid $light-gray-1-overlay;
  }
}

.info {
  white-space: pre-wrap;

  a {
    color: #0000EE;
  }
}
