// Primary Base Colors
$amway-black: #2C2C2C;
$amway-white: #FFFFFF;
$amway-dark-purple: #38539A;
$amway-light-purple: #F1F2FF;

// Secondary Accent Colors
$amway-dark-blue: #396E75;
$amway-light-blue: #E5FDFF;
$amway-dark-green: #10804D;
$amway-light-green: #008EAD;
$amway-dark-orange: #A65523;
$amway-light-orange: #FFF1E8;
$amway-dark-red: #7F3E3E;
$amway-light-red: #FFE2E2;
$amway-dark-yellow: #62625A;
$amway-light-yellow: #F8F8E7;

// Additional Support Colors
$light-gray-2: #F4F4F4;
$light-gray-1-overlay: rgba(228, 228, 228, 0.7);
$light-gray-1-spacer: #E4E4E4;
$light-black-spacer: rgba(0, 0, 0, 0.30);
$mid-gray-on-black: #949494;
$mid-gray-on-white: #707070;
$success-green-dark: #107F47;
$success-green-light: #F0FAE5;
$alert-gold-dark: #A36A00;
$alert-gold-light: #FFF9F0;
$error-red-dark: #D91734;
$error-red-light: #FDF4F4;
$disabled-color: rgba(0, 0, 0, 0.40);

// Theme Colors
$amway-dark-accent: #38539A;
$amway-light-accent: #F1F2FF;
