@font-face {
  font-family: "GT-Walsheim-regular";
  src: url("../assets/fonts/gt-walsheim-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "GT-Walsheim-medium";
  src: url("../assets/fonts/gt-walsheim-medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "GT-Walsheim-bold";
  src: url("../assets/fonts/gt-walsheim-bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "source-sans-pro-regular";
  src: url("../assets/fonts/source-sans-pro-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "source-sans-pro-medium";
  src: url("../assets/fonts/source-sans-pro-medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "source-sans-pro-bold";
  src: url("../assets/fonts/source-sans-pro-bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
