div#root {
  .ant-card {
    transition: box-shadow 250ms;
  }

  .ant-affix .ant-card {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  }

  .ant-input,
  .ant-input-number,
  .ant-input-number-group-wrapper,
  .ant-input-number-input {
    font-weight: 500;
    width: 100%;
    color: $amway-light-green;
    padding: 0;
  }

  .ant-input-number-borderless {
    border: 0;
  }

  .ant-input-group-addon,
  .ant-input-number-disabled,
  .ant-input-number-group-addon {
    background-color: transparent;
    padding: 0px 8px 0px 0px;
  }

  td .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form {
    .ant-form-item-label {
      padding: 0 0 4px;
      line-height: 1.5715;
      white-space: initial;
      text-align: left;

      &>label {
        font-weight: 500;
        display: block;

        .ant-form-item-tooltip {
          color: $amway-light-green;
          font-size: 14px;
          line-height: 11px;
          float: right;
        }
      }
    }

    @media (max-width: 575px) {
      flex-direction: column;

      .ant-form-item {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .ant-form-item-label,
      .ant-form-item-control {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  .ant-btn-primary {
    background-color: $amway-light-green;
    border: none;
  }

  .ant-collapse {

    &>.ant-collapse-item {
      background-color: $amway-white;
      border: none;
    }

    .ant-collapse-header {
      border: 1px solid $amway-black;
      color: $amway-black;
      border-radius: 26px;
      background-color: transparent;
      padding-left: 24px;
      padding-right: 44px;
      min-height: 50px;
    }

    .ant-collapse-content {
      border: none;
      padding: 18px 16px 32px 16px;
    }

    .ant-collapse-item-active>.ant-collapse-header {
      border: none;
      color: $amway-white;
      background-color: $amway-light-green;
    }

    .ant-collapse-header-text {
      font-size: 16px;
      line-height: 21px;
      display: flex;
      flex-direction: row;

      .anticon {
        display: block;
        font-size: 21px;
        margin-right: 16px;
      }
    }

    .ant-collapse-expand-icon {
      .anticon {
        margin-right: 8px;
      }
    }

    &>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow.anticon {
      font-size: 18px;
      color: $mid-gray-on-white;
    }
  }

  .ant-select {
    .ant-select-selector {
      padding: 0px;
    }

    .ant-select-selection-item {
      font-weight: 500;
      padding: 8px 17px 8px 0px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .ant-list-item-action {
    margin-left: 16px;
  }

  .ant-layout-header {
    height: calc(48px + constant(safe-area-inset-top));
    height: calc(48px + env(safe-area-inset-top));
    padding-top: calc(4px + constant(safe-area-inset-top));
    padding-top: calc(4px + env(safe-area-inset-top));
  }
}

.ant-drawer {

  .ant-drawer-content {
    padding: 0px 24px;

    .ant-drawer-header {
      padding-top: calc(16px + constant(safe-area-inset-top)) !important;
      padding-top: calc(16px + env(safe-area-inset-top)) !important;
      display: block;
      border: none;
      padding: 12px 0px 0px 0px;

      .ant-drawer-header-title {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 60px;

        .ant-drawer-title {
          flex: 1;
          text-align: center;
          line-height: 18px;
          font-size: 16px;
          font-weight: 500;
          color: $amway-black;
          font-family: 'source-sans-pro-medium', 'Arial';
        }

        .ant-drawer-close {
          margin-right: 0px;
          text-align: end;
          font-size: 18px !important;
          color: $amway-black;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.ant-carousel {
  .slick-dots li button {
    width: 10px !important;
    height: 10px !important;
    border-radius: 100% !important;
    background: gray !important;
  }

  .slick-dots li.slick-active {
    width: 16px !important;

    .button {
      width: 10px !important;
      height: 10px !important;
      border-radius: 100% !important;
      background: black !important;
    }
  }
}
