@import "@styles/variables.scss";

.wrapper {
  padding: 24px;
}

.container {
  margin-top: 48px;
  flex: 1;

  h2 {
    font-family: 'source-sans-pro-bold', 'Arial';
    font-size: 18px;
  }

  p {
    font-family: 'source-sans-pro-regular', 'Arial';
    font-size: 15px;
    margin-top: 16px;
  }
}

.dropdown {
  font-size: 16px;
  line-height: 21px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-bottom: 2px solid #E4E4E4;

  .title {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-family: 'source-sans-pro-regular', 'Arial';
  }

  .option {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    color: #00829E;
    cursor: pointer;
    width: 100%;

    .value {
      color: #00829E;
      font-family: 'source-sans-pro-medium', 'Arial';
      flex: 1;
    }

    .icon {
      margin-left: 10px;
      font-size: 12px;
      flex: 0;
    }
  }
}
